import NextImage from 'next/image'
import { buildImageUrl } from 'cloudinary-build-url'
import { useCallback } from 'react'

const isUrl = (string) =>
  string.match(
    /^(ht|f)tps?:\/\/[a-z0-9-.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?$/,
  )

interface ImageProps {
  className?: string
  cloudName?: string
  publicId?: string
  src?: string
  transforms?: any
  width?: string
  height?: string
  quality?: string
  storageType?: string
  alt?: string
  [props: string]: any
}

const Image: React.FC<ImageProps> = ({
  className,
  cloudName = 'dafnkmuyl',
  publicId,
  src,
  transforms,
  width,
  height,
  quality,
  storageType,
  alt,
  ...rest
}) => {
  function decideStorageDefault(string) {
    if (isUrl(string)) {
      return 'fetch'
    } else {
      return 'upload'
    }
  }

  const cloudinaryUrl =
    cloudName &&
    buildImageUrl(publicId, {
      cloud: {
        cloudName,
        storageType: storageType ? storageType : decideStorageDefault(publicId),
      },
      transformations: {
        resize: (width || height) && {
          width: width ? width : null,
          height: height ? height : null,
        },
        ...transforms,
      },
    })

  const imgSrc = cloudName ? cloudinaryUrl : src

  const cloudinaryLoader = useCallback(() => {
    return imgSrc
  }, [imgSrc])

  return (
    <NextImage
      loader={cloudinaryLoader}
      src={imgSrc}
      className={className}
      width={width}
      height={height}
      quality={quality || 'auto'}
      alt={alt}
      {...rest}
    />
  )
}

export default Image
