export const comingSoonList = [
  '1Password',
  'Adobe After Effects',
  'Adobe Illustrator',
  'Adobe Lightroom CC',
  'Adobe Photoshop',
  'Adobe XD',
  'Affinity Designer',
  'Affinity Photo',
  'Android Studio',
  'Apex Legends',
  'Apple Music',
  'Arduino IDE',
  'Audacity',
  'Bear',
  'Bitbucket',
  'Blender',
  'Brave Browser',
  'Chrome Developer Tools',
  'Code Editor for iOS',
  'Discord',
  'Evernote',
  'Facebook',
  'Feedly',
  'FileZilla',
  'Finder',
  'Fortnite',
  'Framer X',
  'GIMP',
  'GitLab',
  'Google Drive',
  'Guitar Pro',
  'Help Scout',
  'Instagram',
  'iTerm',
  'KanbanMail',
  'Mailchimp',
  'Microsoft Word',
  'Missive',
  'Netflix',
  'New Relic',
  'Notepad++',
  'Obsidian',
  'Origami Studio',
  'PhpStorm',
  'Pocket',
  'Postman',
  'Principle',
  'Proto.io',
  'PuTTy',
  'Python',
  'Quip.com',
  'Roam Research',
  'Sequel Pro',
  'ServiceNow',
  'Shopify',
  'Sketch',
  'SketchUp Pro',
  'Skype',
  'SoundCloud',
  'Sublime Text',
  'Superhuman',
  'TablePlus',
  'Telegram Desktop',
  'TickTick',
  'Todoist',
  'Transmit',
  'Unity 3D',
  'Vivaldi Browser',
  'VLC Player',
  'Webflow',
  'WordPress',
  'Xcode',
  'Zoom for Mac',
  'Zoom for Windows',
  '..and many more',
]
