import { useGetLatestShortcutPractice } from '@hooks/generated'
import { useKeyboardLayout } from '@keyhero/core/src/hooks/useKeyboardLayout'
import { getCombinations } from '@keyhero/core/src/utils/keys'
import CloudinaryImage from '@components/CloudinaryImage'
import KeyCombinations from '@components/KeyCombinations'
import { formatDistance, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

const LatestShortcutPractice = () => {
  const { layout } = useKeyboardLayout()
  const { data } = useGetLatestShortcutPractice({ layout })

  if (data && data.getLatestShortcutPractice) {
    const shortcutPractice = data.getLatestShortcutPractice
    const shortcut = shortcutPractice.userShortcut.shortcut

    const now = new Date()
    const timeAgo = formatDistance(
      zonedTimeToUtc(parseISO(shortcutPractice.insertedAt), 'Etc/UTC'),
      now,
      { addSuffix: true },
    )

    return (
      <div className="flex min-w-full justify-center ">
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 items-center text-sm">
          <div className="inline-flex">Someone practiced </div>
          <KeyCombinations
            combinations={getCombinations(shortcut.shortcutKeys)}
          />
          <div className="flex-row sm:inline-flex space-y-2 sm:space-y-0 sm:space-x-1 items-center text-center ">
            <div className="inline-flex items-center space-x-1">
              <span> for </span>
              <span className="inline-flex items-center">
                <CloudinaryImage
                  alt={shortcut.course.name}
                  publicId={shortcut.course.icon}
                  height="24"
                  width="24"
                />
              </span>
              <span className="font-bold">{shortcut.course.name}</span>
            </div>
            <div>
              <span> {timeAgo}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LatestShortcutPractice
