import React from 'react'
import { useKeyboardLayout } from '@keyhero/core/src/hooks/useKeyboardLayout'
import { getDisplayName } from '@keyhero/core/src/utils/keys'

interface SmallKeyProps {
  name: string
}

const SmallKey: React.FC<SmallKeyProps> = ({ name }) => {
  const { layout } = useKeyboardLayout()
  const displayName = getDisplayName(name, layout)

  return (
    <kbd className="key--small inline-flex justify-center min-w-[40px] px-2 py-4 font-sans bg-white text-gray-600 rounded break-words overflow-x-auto font-medium text-base">
      {displayName}
    </kbd>
  )
}

export default SmallKey
