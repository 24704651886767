import CloudinaryImage from '@components/CloudinaryImage'
import Quote from '../../assets/icons/quote.svg'

const Testimonials = () => {
  return (
    <div className="my-10">
      <section className="bg-blue-800 rounded-lg">
        <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
          <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-blue-900 lg:pr-16">
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg text-white md:flex-grow">
                <Quote className="absolute -top-6 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600" />
                <div className="space-y-2">
                  <p className="relative">
                    It&apos;s satisfying, feels like a game.
                  </p>
                  <p className="relative">
                    It&apos;s gotten me thinking about incrementally improving
                    my setup. Alt + F12 peeks a definition in VS Code. I had no
                    idea. Some of these shortcuts are super high impact - how
                    many times would I have wanted to quickly see the
                    definition? And ALL THESE YEARS I didn&apos;t know the
                    shortcut to show the autocomplete docs for a function.
                  </p>
                </div>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div className="relative flex-shrink-0 inline-flex rounded-full border-2 border-white h-12 w-12">
                    <CloudinaryImage
                      className="absolute rounded-full"
                      alt="Venkat Dinavahi"
                      publicId="testimonials/venkat-dinavahi.jpg"
                      layout="fill"
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-base text-white">Venkat Dinavahi</div>
                    <div className="text-base text-blue-200">
                      Co-founder and CTO, Turtle
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
          <div className="py-12 px-4 border-t-2 border-blue-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg text-white md:flex-grow">
                <Quote className="absolute -top-6 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600" />
                <div className="space-y-2">
                  <p className="relative">
                    Keyhero makes it easy for me to learn keyboard shortcuts.
                  </p>
                  <p className="relative">
                    Even in the short time that I&apos;ve used it, I&apos;m
                    already using shortcuts more frequently in all my programs
                    &#40;not just the ones I&apos;m learning on Keyhero&#x29;.
                  </p>
                  <p className="relative">
                    I&apos;ve noticed my productivity increase substantially
                    since I started.
                  </p>
                </div>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div className="relative flex-shrink-0 inline-flex rounded-full border-2 border-white h-12 w-12">
                    <CloudinaryImage
                      className="absolute rounded-full"
                      alt="Hilary Heubeck"
                      publicId="testimonials/hilary-heubeck.jpg"
                      layout="fill"
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-base text-white">Hilary Heubeck</div>
                    <div className="text-base text-blue-200">
                      Interior Designer, Price Modern
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Testimonials
