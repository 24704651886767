import SmallKey from '@keyhero/core/src/components/SmallKey'
import { classNames } from '@utils/helpers'
import { useEffect, useState } from 'react'

interface KeyCombinationsProps {
  combinations: any[]
  onlyFirstCombination?: boolean
  vertical?: boolean
}

const KeyCombinations: React.FC<KeyCombinationsProps> = ({
  combinations,
  onlyFirstCombination = false,
  vertical = false,
}) => {
  const [combinationsToRender, setCombinationsToRender] = useState(combinations)

  useEffect(() => {
    if (combinations) {
      if (onlyFirstCombination) {
        setCombinationsToRender([combinations[0]])
      } else {
        setCombinationsToRender(combinations)
      }
    }
  }, [combinations, onlyFirstCombination])

  return (
    <ul
      className={classNames(
        vertical ? 'flex-col space-y-2' : 'space-x-2',
        'flex',
      )}
    >
      {combinationsToRender.map((combination, combinationIndex) => (
        <li
          key={combinationIndex}
          className={classNames(
            vertical ? 'space-y-2 text-right' : 'space-x-2',
          )}
        >
          <div className="inline-flex items-center space-x-2">
            {combinationIndex > 0 && <div>or</div>}
            {combination.map(({ key: { name } }, index) => (
              <SmallKey key={index} name={name} />
            ))}
          </div>
        </li>
      ))}
    </ul>
  )
}

export default KeyCombinations
