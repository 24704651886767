import React from 'react'
import { isMacOs } from 'react-device-detect'
import { groupBy } from './helpers'

const keys = {
  8: 'Backspace',
  9: 'Tab',
  13: 'Enter',
  16: 'Shift',
  17: 'Ctrl',
  18: 'Alt',
  19: 'Pause/Break',
  20: 'Caps Lock',
  27: 'Esc',
  32: 'Space',
  33: 'PgUp',
  34: 'PgDn',
  35: 'End',
  36: 'Home',
  37: '←',
  38: '↑',
  39: '→',
  40: '↓',
  44: 'PrtSc',
  45: 'Insert',
  46: 'Delete',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  65: 'A',
  66: 'B',
  67: 'C',
  68: 'D',
  69: 'E',
  70: 'F',
  71: 'G',
  72: 'H',
  73: 'I',
  74: 'J',
  75: 'K',
  76: 'L',
  77: 'M',
  78: 'N',
  79: 'O',
  80: 'P',
  81: 'Q',
  82: 'R',
  83: 'S',
  84: 'T',
  85: 'U',
  86: 'V',
  87: 'W',
  88: 'X',
  89: 'Y',
  90: 'Z',
  91: 'Cmd',
  92: 'Cmd',
  186: ';',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  192: '`',
  219: '[',
  220: '/',
  221: ']',
  222: "'",
}

const keyForEvent = (event: React.KeyboardEvent) => {
  // The meta keys is different on a Mac
  if (event.key === 'Meta') {
    return isMacOs ? 'Cmd' : 'Ctrl'
  }

  // The alt key is different on a Mac
  if (event.key === 'Alt') {
    return isMacOs ? 'Opt' : 'Alt'
  }

  // The space key is a physical space
  if (event.key === ' ') {
    return 'Space'
  }

  // Backspace on a Mac is called Delete
  if (event.key === 'Backspace') {
    return isMacOs ? 'Delete' : 'Backspace'
  }

  // If we define our own mapping, use that
  if (event.which in keys) {
    return keys[event.which as keyof typeof keys]
  }

  // Default to the KeyboardEvent.key
  return event.key
}

const keyCodeForEvent = (event: React.KeyboardEvent) => {
  return event.keyCode
}

type Key = {
  shortcutCombinationId: string
}

const getCombinations = (shortcutKeys: any) => {
  const combinationsObj = groupBy(
    shortcutKeys,
    (key: Key) => key.shortcutCombinationId,
  )
  return Object.keys(combinationsObj).map((k) => combinationsObj[k])
}

const getQwertyMacDisplayName = (name: string) => {
  switch (name) {
    case 'Alt':
      return 'Opt'
    default:
      return name
  }
}

const getDisplayName = (name: string, layout: string) => {
  switch (layout) {
    case 'qwerty':
      return name
    case 'qwerty_mac':
      return getQwertyMacDisplayName(name)
    default:
      return name
  }
}

export { getCombinations, getDisplayName, keyCodeForEvent, keyForEvent }

export default keys
