import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailbox, faCircleNotch } from '@fortawesome/pro-solid-svg-icons'
import { useJoinWaitlist } from '@hooks/generated'
import { classNames } from '@utils/helpers'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

interface WaitlistFormProps {
  focus?: boolean
  onFocus?: () => void
}

const WaitlistForm: React.FC<WaitlistFormProps> = ({ focus, onFocus }) => {
  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)

  const mutation = useJoinWaitlist({
    onSuccess: (data) => {
      setResult(data)
    },
  })

  const {
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm()

  const joinWaitlist = async (inputs) => {
    try {
      return await mutation.mutateAsync({ inputs })
    } catch (error) {
      if (error instanceof Error) setError(error.message)
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  useEffect(() => {
    if (isDirty) {
      setError(null)
      setResult(null)
    }
  }, [isDirty, reset])

  useEffect(() => {
    if (focus) {
      setFocus('email')
      onFocus()
    }
  }, [focus, onFocus, setFocus])

  return (
    <div>
      {result ? (
        <div className="flex mt-2 p-3 rounded-md w-full bg-lime-600 items-center">
          <p className="text-white text-lg">
            <FontAwesomeIcon icon={faMailbox} className="mr-2" /> Check your
            inbox for <span className="font-bold">{result.res.email}</span> to
            confirm
          </p>
        </div>
      ) : (
        <form className="mt-3 sm:flex" onSubmit={handleSubmit(joinWaitlist)}>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <div className="block w-full sm:flex-1">
            <input
              id="email"
              name="email"
              placeholder="Enter your email"
              {...register('email', { required: true })}
              type="email"
              className={classNames(
                errors.email || error
                  ? 'border-pink-500 text-pink-600 focus:border-pink-500 placeholder:text-pink-600'
                  : 'border-slate-300',
                'block w-full p-3 text-base rounded-md placeholder-gray-500 shadow-sm border-2 focus:ring-blue-500 focus:border-blue-500 border-slate-300',
              )}
            />
            {errors.email?.type === 'required' && (
              <p className="mt-2 text-pink-600 text-sm">Email is required</p>
            )}
            {error && <p className="mt-2 text-pink-600 text-sm">{error}</p>}
          </div>
          <div className="mt-3 w-full sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-start sm:w-auto">
            <button
              disabled={isSubmitting}
              type="submit"
              className={classNames(
                'px-6 h-[52px] border border-transparent text-base font-medium rounded-md text-white shadow-sm bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 min-w-full sm:min-w-fit',
              )}
            >
              {isSubmitting ? (
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
              ) : (
                <span>Notify me</span>
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default WaitlistForm
