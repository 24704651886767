import CloudinaryImage from '@components/CloudinaryImage'
import { comingSoonList } from '@components/Marketing/data'
import { useCourses } from '@hooks/generated'

const Apps = () => {
  const { data } = useCourses()

  if (data && data.courses) {
    const { courses } = data

    return (
      <div className="my-10 text-center">
        <h2 className="text-xl font-bold text-gray-900 uppercase">
          What&apos;s included
        </h2>
        <p className="my-2 text-lg">
          Learn shortcuts for lots of apps right now
        </p>

        <ul role="list" className="block mt-4 space-y-6 max-w-xl mx-auto">
          {courses.map((course) => (
            <li key={course.id} className="inline-flex min-w-[48px] mr-6">
              <CloudinaryImage
                alt={course.name}
                publicId={course.icon}
                height="48"
                width="48"
              />
            </li>
          ))}
        </ul>
        <h3 className="mt-12 text-lg font-bold text-gray-700 uppercase">
          Coming Soon
        </h3>
        <p className="my-2 text-lg">More apps are being added all the time</p>
        <ul
          role="list"
          className="my-6 grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-3 mx-auto text-left"
        >
          {comingSoonList.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    )
  }
}

export default Apps
